<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-card class="elevation-0">
        <v-card-title class="primary white--text">Gravação</v-card-title>
        <v-card-text class="mt-2">
          <v-row>
            <v-col cols="12" justify="center" align="center">
              <v-btn class="primary" @click="startRecording" :disabled="isRecording" fab small>
                <v-icon>mdi-play</v-icon>
              </v-btn>
              <v-btn class="error" @click="stopRecording" :disabled="!isRecording" fab small>
                <v-icon>mdi-stop</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" v-if="showMessageRecording">
              <transition name="fade">
                <p class="recording-message">{{ isRecordingMessage }}</p>
              </transition>
            </v-col>
            <v-col cols="12" align="center" justify="center">
              <audio v-if="audioSrc" :src="audioSrc" controls></audio>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card class="elevation-0">
        <v-card-title class="primary white--text">Voz distorcida</v-card-title>
        <v-card-text class="mt-2 text-center" v-if="audioSrc">
          <v-btn fab small class="success" @click="playDistortedAudio" :disabled="isPlaying">
            <v-icon>mdi-play</v-icon>
          </v-btn>
          <v-btn fab small class="error" @click="stopDistortedAudio" :disabled="!isPlaying">
            <v-icon>mdi-stop</v-icon>
          </v-btn>
          <br /><br />
          <p class="recording-message">{{ showDistorcedWaiting ? isShowingDistorcedMessage : (isPlaying ? 'A reproduzir' : 'Carregue no play')}}</p>
          <p class="recording-message">A Ignoto garante a proteção da identidade do denunciante e, compromete-se ao total envio da voz modificada</p>
        </v-card-text>
        <v-card-text class="mt-2" v-else>
          Grave uma mensagem de voz. A IGNOTO irá alterar a mensagem para que a sua voz fique distorcida.
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import PitchShift from 'soundbank-pitch-shift';

export default {
  name: "VoiceRecorder",
  props: {
    value: {
      type: Blob, // O tipo esperado do áudio será um Blob
      default: null
    }
  },
  data() {
    return {
      isRecordingMessage: "Clique no botão para gravar",
      rec: null,
      audioChunks: [],
      audioSrc: "",
      isRecording: false,
      audioContext: null,
      pitchShift: null,
      showMessageRecording: false,
      isPlaying: false,
      showDistorcedWaiting: false,
      isShowingDistorcedMessage: 'Estamos a produzir o conteúdo...'
    };
  },
  methods: {
    async startRecording() {
      this.isRecordingMessage = 3;
      this.showMessageRecording = true;

      let countdown = 2;
      const intervalId = setInterval(() => {
        this.isRecordingMessage = countdown;
        countdown--;

        if (countdown < 0) {
          clearInterval(intervalId);
          this.isRecordingMessage = "Recording...";
          this.isRecording = true;
          this.startRecordingStream();
        }
      }, 1000);
    },
    stopRecording() {
      if (this.rec && this.isRecording) {
        this.rec.stop();
        this.isRecording = false;
        this.isRecordingMessage = "Em baixo poderá ouvir a gravação original.";
      }
    },
    handleRecording(stream) {
      this.rec = new MediaRecorder(stream);
      this.audioChunks = [];
      this.rec.start();

      this.rec.ondataavailable = (event) => {
        this.audioChunks.push(event.data);
        if (this.rec.state === "inactive") {
          const blob = new Blob(this.audioChunks, { type: "audio/mp3" });
          this.audioSrc = URL.createObjectURL(blob);
        }
      };

      this.rec.onstop = () => {
        stream.getTracks().forEach((track) => track.stop());
      };
    },
    async startRecordingStream() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.handleRecording(stream);
      } catch (error) {
        console.error("Error accessing microphone:", error);
        this.isRecordingMessage = "Erro ao acessar o microfone. Verifique as permissões.";
      }
    },
    playDistortedAudio() {
      this.showDistorcedWaiting = true;
      setTimeout(() => {
        this.generateDistortedAudio();
      }, 2000);
    },
    async generateDistortedAudio() {
      if (!this.audioSrc || this.isPlaying) return;
      
      this.isPlaying = true;
      const response = await fetch(this.audioSrc);
      const arrayBuffer = await response.arrayBuffer();
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();

      this.pitchShift = PitchShift(this.audioContext);
      this.pitchShift.transpose = -7;
      this.pitchShift.wet.value = 0.85;
      this.pitchShift.dry.value = 0.15;

      const biquadFilter = this.audioContext.createBiquadFilter();
      biquadFilter.type = "lowpass";
      biquadFilter.frequency.setValueAtTime(1500, this.audioContext.currentTime);
      biquadFilter.Q.value = 1;

      this.pitchShift.connect(biquadFilter);
      biquadFilter.connect(this.audioContext.destination);

      this.audioContext.decodeAudioData(arrayBuffer, async (audioBuffer) => {
        const source = this.audioContext.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(this.pitchShift);

        const mediaStreamDestination = this.audioContext.createMediaStreamDestination();
        this.pitchShift.connect(mediaStreamDestination);

        const recorder = new MediaRecorder(mediaStreamDestination.stream);
        const distortedChunks = [];

        recorder.ondataavailable = (event) => {
          distortedChunks.push(event.data);
        };

        recorder.onstop = () => {
          const distortedBlob = new Blob(distortedChunks, { type: 'audio/mp3' });
          this.$emit('input', distortedBlob);
          this.isPlaying = false;
        };

        recorder.start();
        source.start();

        source.onended = () => {
          recorder.stop();
        };

        this.showDistorcedWaiting = false;
      });
    },
    stopDistortedAudio() {
      if (this.audioContext) {
        this.audioContext.close();
        this.isPlaying = false;
      }
    },
  },
};
</script>

<style scoped>
.recording-message {
  font-size: 1.2rem;
  color: inherit;
  text-align: center;
  margin-top: 10px;
  background-color: transparent;
  padding: 10px;
  border-radius: 5px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
